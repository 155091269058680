//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from 'lodash';
export default {
  name: 'SimpleTable',
  props: {
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    keyFieldName: {
      type: String,
      required: false,
      default: 'id'
    },
    draggable: {
      type: Boolean,
      required: false,
      default: false
    },
    onDragEnd: {
      type: Function,
      required: false,
      default: function _default() {}
    },
    onDragChange: {
      type: Function,
      required: false,
      default: function _default() {}
    },
    dragCellStyle: {
      type: undefined,
      required: false,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {
    state: function state() {
      if (this.items.length < 1) {
        return false;
      }

      return true;
    }
  },
  methods: {
    at: function at(item, location) {
      return _.at(item, location);
    }
  }
};