//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'PageSection',
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};